<template>
    <div class="artist-profile-layout">
        <projectIsUnderConstructionWarning style="z-index: 1;"></projectIsUnderConstructionWarning>
        <slot></slot>
    </div>
</template>

<script>
import projectIsUnderConstructionWarning from "@/components/projectIsUnderConstructionWarning.vue";
export default {
    name: "artistProfileLayout",
    components: {
        projectIsUnderConstructionWarning
    }
}
</script>

<style lang="scss">

</style>