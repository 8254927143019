<template>
	<artistProfileLayout>
		<div class="artist_profile">
			<div class="artist_profile_head">
				<div v-if="headBackground" class="profile_head_background" v-bind:style="{'background-image': 'url('+(headBackground)+')'}"></div>
				<div v-else class="profile_head_background default"></div>
				<v-container>
					<div class="artist_data">
						<artistProfileAvatar :isAdmin="ARTIST.owner === true" :artistID="id"></artistProfileAvatar>

						<div class="artist_info">
							<div class="mobile_artist_name" v-if="mobile">
								<h2 class="artist_name">{{ artist.name }}</h2>

								<!-- more button -->
								<v-menu
									transition="slide-y-transition"
									:close-on-content-click="true"
									offset-y
									nudge-left="21"
									nudge-bottom="10"
									origin="top center"
									content-class="menu-pointer charcoal">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
											<span class="material-icons">more_vert</span>
										</v-btn>
									</template>
									<v-list>
										<!-- popup share link -->
										<v-list-item>
											<v-dialog transition="dialog-bottom-transition" max-width="600">
												<template v-slot:activator="{ on, attrs }">
													<button v-ripple type="button" v-bind="attrs" v-on="on">Share</button>
												</template>
												<template v-slot:default="dialog">
													<div class="content_popup_share">
														<div class="head_popup">Share</div>
														<div class="input_box">
															<input type="text" id="input-share-link" v-model="shareLink">
															<button v-ripple type="button" @click="copyArtistLink">Copy</button>
														</div>
														<button v-ripple type="button" @click="dialog.value = false" class="close">Close</button>
													</div>
												</template>
											</v-dialog>
										</v-list-item>
										<!-- Report -->
										<v-list-item v-ripple>
											<button type="button" class="report">Report</button>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>

							<h2 class="artist_name" v-if="!mobile">
<!--								<template v-if="viewContent === 'edit-profile' && tabs === 2">-->
<!--									<input type="text" v-model.trim="artist.name" :class="{-->
<!--										success_name: UNIQUE_ARTIST_NAME === true && artist.name !== ARTIST.name,-->
<!--										error_name: UNIQUE_ARTIST_NAME === false && artist.name !== ARTIST.name-->
<!--									}">-->
<!--								</template>-->
								<template>
									{{ artist.name }}
								</template>
								<!-- <button v-if="ARTIST.owner === true && viewContent !== 'edit-profile'" type="button" class="edit_profile" @click="editArtistProfile"></button>-->
								<button type="button" v-if="ARTIST.owner === false" @click="dialogAreYouThisArtistVisible = true" class="is-this-you-link">
									(Is this you?)
								</button>
							</h2>

							<div class="follows" v-if="ARTIST">
								<div class="followers">
									<span class="count">{{ ARTIST.followersCount }}</span>
									<div>Followers</div>
								</div>
							</div>

							<are-you-this-artist-dialog v-if="dialogAreYouThisArtistVisible" :artist="ARTIST" v-model="dialogAreYouThisArtistVisible">
							</are-you-this-artist-dialog>

						</div>
					</div>
				</v-container>
			</div>

			<div class="wrap_profile_music_events">
				<div class="head_profile_music_events">
					<v-container>
						<div class="head_content">
							<v-tabs
								background-color="transparent"
								color="#fff"
								v-model="tabs">
								<v-tab v-for="tab in tabsSection" :key="tab.index">
									{{ tab.name }}
								</v-tab>
							</v-tabs>


							<div class="actions">
								<template v-if="ARTIST.owner === true">
									<button type="button" class="edit_profile" v-if="viewContent !== 'edit-profile'" @click="editArtistProfile" v-ripple>
										Edit profile
									</button>
								</template>
								<template v-else-if="ARTIST && ARTIST.owner === false">
									<template v-if="actionButtonsLoader === true || followBtnLoader || messageBtnLoader || supportBtnLoader">
										<v-skeleton-loader v-if="followBtnLoader || !this.AUTH_DATA.accessToken.length" max-width="300" height="34" type="button"></v-skeleton-loader>
										<v-skeleton-loader v-if="messageBtnLoader || !this.AUTH_DATA.accessToken.length" max-width="300" height="34" type="button"></v-skeleton-loader>
										<v-skeleton-loader v-if="supportBtnLoader || !this.AUTH_DATA.accessToken.length" max-width="300" height="34" type="button"></v-skeleton-loader>
									</template>
									<template v-if="actionButtonsLoader === false && this.AUTH_DATA.accessToken.length">
										<button type="button" class="follow_btn" v-if="this.isFollow === false && !followBtnLoader" @click="follow(ARTIST.id)">
											<span class="icon"></span>
											Follow
										</button>
										<button type="button" class="follow_btn following" v-else-if="!followBtnLoader" @click="unfollow(ARTIST.id)"
										        @touchend="hoverFollowingButton = false"
										        @mouseover="hoverFollowingButton = true"
										        @mouseleave="hoverFollowingButton = false">
											<span class="icon"></span>
											<template v-if="hoverFollowingButton === true">Unfollow</template>
											<template v-else>Following</template>
										</button>
										<button type="button" class="message_btn" @click="showComingSoonDialog = true">
											<span class="icon"></span>
											Message
										</button>
                    <button v-if="!copiedArtistLink" type="button" class="share_btn" @click="copyArtistLink">
                      <span class="icon"></span>
                      Share
                    </button>
                    <button v-if="copiedArtistLink" type="button" class="share_btn">
                      Copied!
                    </button>
									</template>

                  <template v-if="actionButtonsLoader === false && !this.AUTH_DATA.accessToken.length">
                    <button type="button" class="follow_btn" @click="dialogNeedLoginVisible = true">
                      <span class="icon"></span>
                      Follow
                    </button>
                    <button type="button" class="message_btn" @click="dialogNeedLoginVisible = true">
                      <span class="icon"></span>
                      Message
                    </button>
                    <button v-if="!copiedArtistLink" type="button" class="share_btn" @click="copyArtistLink">
                      <span class="icon"></span>
                      Share
                    </button>
                    <button v-if="copiedArtistLink" type="button" class="share_btn">
                      Copied!
                    </button>
                  </template>
								</template>

								<!-- more button -->
								<v-menu
									transition="slide-y-transition"
									:close-on-content-click="true"
									offset-y
									nudge-left="21"
									nudge-bottom="10"
									origin="top center"
									content-class="menu-pointer charcoal">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
											<span class="material-icons">more_vert</span>
										</v-btn>
									</template>
									<v-list>
										<!-- popup share link -->
<!--										<v-list-item>-->
<!--											<v-dialog transition="dialog-bottom-transition" max-width="600">-->
<!--												<template v-slot:activator="{ on, attrs }">-->
<!--													<button v-ripple type="button" v-bind="attrs" v-on="on">Share</button>-->
<!--												</template>-->
<!--												<template v-slot:default="dialog">-->
<!--													<div class="content_popup_share">-->
<!--														<div class="head_popup">Share</div>-->
<!--														<div class="input_box">-->
<!--															<input type="text" id="input-share-link" v-model="shareLink">-->
<!--															<button v-ripple type="button" @click="copyArtistLink">Copy</button>-->
<!--														</div>-->
<!--														<button v-ripple type="button" @click="dialog.value = false" class="close">Close</button>-->
<!--													</div>-->
<!--												</template>-->
<!--											</v-dialog>-->
<!--										</v-list-item>-->
										<!-- Report -->
										<v-list-item v-ripple>
											<button type="button" class="report">Report</button>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</div>
					</v-container>
				</div>
				<div class="content_profile_music_events">
					<v-tabs-items touchless v-model="tabs">

						<!-- Music Tab -->
						<v-tab-item :transition="false">
							<div class="music_section artist_releases">

								<!--                                <div class="popular_tracks" style="display: none;">-->

								<!--                                </div>-->


								<div class="uploaded_music"
								     v-if="(releasesAlbumsLoader && !ARTIST_PROFILE_RELEASES_ALBUMS.length) || (!releasesAlbumsLoader && ARTIST_PROFILE_RELEASES_ALBUMS.length)">
									<v-container>
										<div class="head_profile_content" :class="{'nothing_releases': !ARTIST && !ARTIST_PROFILE_RELEASES_ALBUMS.length}">
                                        <span class="_head_name">
                                            Releases
                                        </span>
										</div>

										<div v-if="releasesAlbumsLoader" class="wrap_music skeleton-loader">
											<albumGridSkeletonLoader v-for="index in 6" :key="index" :parent="'default_grid_albums'"></albumGridSkeletonLoader>
										</div>

										<div class="wrap_music group" v-if="!releasesAlbumsLoader && ARTIST_PROFILE_RELEASES_ALBUMS.length">
											<template v-for="group of groupArtistAlbums">

												<div class="item" v-for="(item, index) in group" :key="index" >
													<div class="wrap_year">
														<span class="year" v-if="index === 0">{{ String(Object.keys(group[0])) }}</span>
														<span class="line"></span>
													</div>
													<albumGrid
														v-for="album of item"
														:key="album.id"
														:hide-artist="true"
														:album="album"
														:playZone="ARTIST_PROFILE_RELEASES_ALBUMS">
													</albumGrid>
												</div>
											</template>
										</div>

									</v-container>
								</div>

								<div class="appears_on" v-if="appearsAlbums.length">
									<div class="container">
										<div class="head_profile_content">
											<h3 class="_head_name">Appears on</h3>
										</div>
										<div class="wrap_music">
											<albumGrid v-for="album in appearsAlbums"
											           :key="album.id"
											           :album="album"
											           :hide-artist="true"
											           :playZone="appearsAlbums"
											></albumGrid>
										</div>
									</div>
								</div>

								<template v-if="(!ARTIST_PROFILE_RELEASES_ALBUMS.length && !releasesAlbumsLoader) && !appearsAlbums.length">
									<nothingHereYet></nothingHereYet>
								</template>

							</div>
						</v-tab-item>

						<!-- Events Tab -->
						<v-tab-item :transition="false">
							<div class="events_section">
								<div class="uploaded_events">
									<v-container>
										<div class="head_profile_content">
                                            <span class="_head_name">
                                                Coming up
                                            </span>
										</div>
										<nothingHereYet></nothingHereYet>

									</v-container>
								</div>
							</div>
						</v-tab-item>

						<!-- About Tab -->
						<v-tab-item :transition="false">
							<div class="artist_about">
								<v-container class="v_container_info">
									<div class="head_profile_content">
                                    <span class="_head_name">
                                       Info
                                    </span>
									</div>
									<div class="wrap_about_section">
										<div class="artist_owner" v-if="ARTIST_OWNER">
											Author
											<span @click="redirectToUserProfile(ARTIST_OWNER.id, ARTIST_OWNER.identifier)">
												{{ ARTIST_OWNER.fullName }}
											</span>
										</div>
									</div>
								</v-container>
								<v-container class="v_container_bio">
									<div class="head_profile_content">
                                    <span class="_head_name">
                                       Bio
                                    </span>
									</div>
									<div class="wrap_about_section">
										<template v-if="viewContent === 'edit-profile'">
											<TextareaAutosize v-model="artist.description"></TextareaAutosize>
											<div class="wrap_action_btn">
												<button type="button" class="save" v-if="UNIQUE_ARTIST_NAME === true" v-ripple @click="changeArtistData" :class="{loading: changeArtistLoader}">
													<v-progress-circular :width="2" indeterminate></v-progress-circular>
													Save
												</button>
												<div v-else class="secondary_btn disabled button">Save</div>

												<button type="button" class="close" v-ripple @click="cancel">Cancel</button>
											</div>
										</template>

										<template v-else>
											<template v-if="artist.description">
												<!--                                                <div v-html="formattedBio"></div>-->
												<div v-html="artist.description.replace(/\n/g, '<br>')"></div>
											</template>
											<template v-else>
												<nothingHereYet></nothingHereYet>
<!--												<div class="nothing_here_yet">-->
<!--													<div class="container">-->
<!--														Nothing here yet-->
<!--													</div>-->
<!--												</div>-->
											</template>
										</template>
									</div>
								</v-container>

							</div>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</div>


			<ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
			<dialogNeedLogin v-if="dialogNeedLoginVisible" v-model="dialogNeedLoginVisible"></dialogNeedLogin>
			<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>
<!--			<v-dialog-->
<!--				v-if="errorDialog"-->
<!--				v-model="errorDialog"-->
<!--				max-width="600">-->
<!--				<div class="need_login_dialog style_dialog">-->
<!--					<h2 class="header">-->
<!--						Oops!-->
<!--					</h2>-->
<!--					<div class="cnt">-->
<!--						Something went wrong, maybe such an artist name already exists. Please try again or contact support-->
<!--					</div>-->
<!--					<div class="wrap_actions">-->
<!--						<button type="button" class="cancel" @click="errorDialog = false" v-ripple>Ok</button>-->
<!--						<a v-if="FACEBOOK" :href="FACEBOOK" target="_blank" class="facebook">Contact</a>-->
<!--					</div>-->
<!--				</div>-->
<!--			</v-dialog>-->

		</div>
	</artistProfileLayout>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import moment from 'moment';
import artistProfileLayout from "@/layouts/profiles/artistProfileLayout.vue";

import artistProfileAvatar from "@/components/profile/artistProfileAvatar";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader";

import albumGrid from '@/components/musicReleases/albumGrid';

const ComingSoonDialog = () => import("@/components/dialogs/comingSoonDialog");
const dialogNeedLogin = () => import('@/components/dialogs/needLogin');
const areYouThisArtistDialog = () => import('@/components/dialogs/areYouThisArtist');
const nothingHereYet = () => import("@/components/small/nothingHereYet");
const errorDialog = () => import('@/components/dialogs/error');

import isMobile from "@/mixins/isMobile";
import checkAccessToken from "@/mixins/checkAccessToken";
import Defer from '@/utils/defer';

export default {
	name: 'artist-profile',
	props: ['id', 'name'],
	components: {
		artistProfileLayout,
		albumGridSkeletonLoader,
		artistProfileAvatar,
		albumGrid,
		ComingSoonDialog,
		areYouThisArtistDialog,
		dialogNeedLogin,
		nothingHereYet,
		errorDialog
		// ModalImage
	},
	mixins: [checkAccessToken, isMobile, Defer()],
	data() {
		return {
			artist: {
				name: '',
				description: '',
			},
			apiUrl: process.env.VUE_APP_API_URL,
			loader: false,
			releasesAlbumsLoader: true,
			changeArtistLoader: false,
			dialogAreYouThisArtistVisible: false,

			viewContent: 'view-profile',
			shareLink: window.location.href,

			tabs: null,
			tabsSection: [
				{index: 0, name: 'Music'},
				{index: 1, name: 'Events'},
				{index: 2, name: 'About'}
			],

			sortByAlbumArtist: [
				'Release Date'
			],
			selectSortByArtistAlbums: 'Release Date',

			headBackground: '',

			ownerID: '',
			groupArtistAlbums: [],
			appearsAlbums: [],
			showComingSoonDialog: false,
			dialogNeedLoginVisible: false,
			artistAlbums: true,
			albums: [],
			isFollow: false,
			followBtnLoader: false,
			messageBtnLoader: false,
			supportBtnLoader: false,
			hoverFollowingButton: false,
			actionButtonsLoader: true,
			errorDialog: false,
			followersCount: null,
      copiedArtistLink: false,
		}
	},

	watch: {
		// id() {
		// 	this.getArtistData();
		// 	this.getReleasesAlbums();
		// 	this.getAppearsOnAlbums();
		// },
		ARTIST: {
			handler() {
				this.getBackground();
			},
			deep: true
		},
		'artist.name'() {
			if (this.viewContent === 'edit-profile' && this.artist.name !== this.ARTIST.name) {
				let formData = new FormData();
				formData.append('name', this.artist.name);
				this.CHECK_UNIQUE_ARTIST_NAME(formData)
					.catch(err => console.log(`CHECK_UNIQUE_ARTIST_NAME, ${err}`));
			}
		},
		tabs() {
			if (this.tabs !== 2) {
				this.viewContent = 'view-profile';
			}
		}
	},
	computed: {
		...mapGetters(['AUTH_DATA', 'userID', 'ARTIST', 'ARTIST_ALBUMS', 'CHANGE_ARTIST_DATA_STATUS', 'MUSIC_RELEASE', 'USER', 'FILE_URL', 'ARTIST_OWNER', 'APPEARS_ON',
			'ARTIST_FOLLOWERS',
			'ARTIST_PROFILE_RELEASES_ALBUMS',
			'ARTIST_PROFILE_APPEARS_ON_ALBUMS',
			'FACEBOOK',
			'UNIQUE_ARTIST_NAME',
			'HAS_FOLLOWED_ARTIST',
		]),
		// formattedBio() {
		//     return this.ARTIST.description.replace(/\n/g, '<br>');
		// }
	},
	created: function () {
		this.moment = moment;
		this.getArtistData();
		this.getReleasesAlbums();
		this.getAppearsOnAlbums();
	},
	mounted() {
		this.toTop();
		let edit = this.$router.history.current.params.edit;
		if (edit === true) {
			this.editArtistProfile();
		}
	},
	methods: {
		...mapActions(['GET_ARTIST', 'GET_ARTIST_ALBUMS', 'CHANGE_ARTIST_DATA',
			'GET_MUSIC_RELEASE', 'GET_USER', 'ADD_FILE', 'GET_ARTIST_OWNER', 'GET_APPEARS_ON',
			'GET_ARTIST_FOLLOWERS', 'ARTIST_FOLLOW', 'ARTIST_UNFOLLOW',
			'GET_ARTIST_PROFILE_RELEASES_ALBUMS',
			'GET_ARTIST_PROFILE_APPEARS_ON_ALBUMS', 'CHECK_UNIQUE_ARTIST_NAME', 'GET_HAS_FOLLOWED_ARTIST'
		]),
		redirectToUserProfile(userID, userName) {
			// const routeData = this.$router.resolve({name: 'user-profile', params: {id: userID, name: window.toHumanFormat(userName)}});
			// const routeData = this.$router.resolve({name: 'user-profile', params: {id: userID, name: userName}});
			// window.open(routeData.href, '_blank');
			this.$router.push({name: 'user-profile', params: {id: userID, name: window.toHumanFormat(userName)}});
		},
		getBackground() {
      if (this.ARTIST.avatar && this.ARTIST.avatar.thumbnail) {
        this.headBackground = this.ARTIST.avatar.thumbnail['160x160'];
      }
		},
		getArtistData() {
      this.loader = true;
			this.actionButtonsLoader = true;
			this.$store.commit('SET_ARTIST_OWNER', '');

			// let artist;
			let artist = this.id || this.name;
			this.GET_ARTIST(artist)
				.then(() => {
					if (!this.ARTIST.id) {
						this.$router.push({name: "404"});
					}
					this.artist.name = this.ARTIST.name;
					this.artist.description = this.ARTIST.description;
					this.followersCount = this.ARTIST.followersCount;

					this.GET_ARTIST_OWNER(this.ARTIST.ownerId)
						.catch(err => console.log(`GET_ARTIST_OWNER, ${err}`));

          if (this.AUTH_DATA.accessToken.length) {
              this.GET_HAS_FOLLOWED_ARTIST(this.ARTIST.id)
                  .then(() => {
                      this.isFollow = this.HAS_FOLLOWED_ARTIST;
                  })
                  .catch(err => console.log(`GET_HAS_FOLLOWED_ARTIST, ${err}`))
                  .finally(() => this.actionButtonsLoader = false);
          } else {
              this.actionButtonsLoader = false;
          }

					// this.GET_ARTIST_FOLLOWERS(this.ARTIST.id)
					// 	.then(() => {
					// 		// this.isFollow = this.ARTIST_FOLLOWERS.items.some(el => el.id === this.userID);
					// 	})
						// .finally(() => this.actionButtonsLoader = false);
				})
				.catch(err => {
					console.error(`getArtist, ${err}`);
					this.$router.push({name: "404"});
				})
				.finally(() => this.loader = false);
		},

		getReleasesAlbums() {
			this.releasesAlbumsLoader = true;
			this.groupArtistAlbums = [];
			let params = {'artist.identifier': this.name, status: 'published'}

			this.GET_ARTIST_PROFILE_RELEASES_ALBUMS(params)
				.then(() => {
					for (let i = 0; i < this.ARTIST_PROFILE_RELEASES_ALBUMS.length; i++) {
						const year = moment(this.ARTIST_PROFILE_RELEASES_ALBUMS[i].releasedAt).year();
						this.groupArtistAlbums[year] = this.groupArtistAlbums[year] || [];
						this.groupArtistAlbums[year].push({[year]: this.ARTIST_PROFILE_RELEASES_ALBUMS[i]});
					}
					this.groupArtistAlbums.reverse();
				})
				.catch(err => console.log(`getReleasesAlbums, ${err}`))
				.finally(() => this.releasesAlbumsLoader = false);
		},
		getAppearsOnAlbums() {
			let params = {
				'playList.song.artist': this.name
			}
			this.GET_ARTIST_PROFILE_APPEARS_ON_ALBUMS(params)
				.then(() => {
					this.appearsAlbums = this.ARTIST_PROFILE_APPEARS_ON_ALBUMS.filter(album => album.artist === null);
				})
				.catch(err => console.log(`getAppearsOnAlbums, ${err}`))
		},

		editArtistProfile() {
			this.viewContent = 'edit-profile';
			this.tabs = 2; // aboutSection
		},
		cancel() {
			this.viewContent = 'view-profile';
			this.tabs = 0; // musicSection
		},

		changeArtistData() {
			this.changeArtistLoader = true;
			// UNIQUE_ARTIST_NAME
			const changeArtistData = {
				id: this.ARTIST.id,
				name: this.artist.name,
				description: this.artist.description,
			};
			this.CHANGE_ARTIST_DATA(changeArtistData)
				.then(() => {
					// this.ARTIST.description.replace(/\n/g, '<br>');
				})
				.catch(err => {
					this.errorDialog = true;
					console.log(`CHANGE_ARTIST_DATA, ${err}`);
				})
				.finally(() => {
					this.changeArtistLoader = false;

					if (this.CHANGE_ARTIST_DATA_STATUS === 'success') {
						this.viewContent = 'view-profile';
					} else {
						this.artist.name = this.ARTIST.name;
						this.artist.description = this.ARTIST.description;
						this.errorDialog = true;
					}
				})
		},
		follow(artistID) {
			if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
				this.followBtnLoader = true;
				this.ARTIST_FOLLOW(artistID)
					.then(result => {
						if (result === 'success') {
							this.followBtnLoader = false;
							this.isFollow = true;
							this.followersCount = this.ARTIST.followersCount +=1;
						} else {
							this.errorDialog = true;
						}
					})
					.catch(err => {
						this.errorDialog = true;
						console.error(`artist follow, ${err}`);
					})
					.finally(() => this.followBtnLoader = false);
			} else {
				this.dialogNeedLoginVisible = true;
			}
		},
		unfollow(artistID) {
			if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
				this.followBtnLoader = true;
				this.ARTIST_UNFOLLOW(artistID)
					.then(result => {
						if (result === 'success') {
							this.followBtnLoader = false;
							this.isFollow = false;
							if (this.ARTIST.followersCount >= 0) {
								this.followersCount = this.ARTIST.followersCount -=1;
							}
						} else {
							this.errorDialog = true;
						}
					})
					.catch(err => {
						this.errorDialog = true;
						console.error(`artist unfollow, ${err}`)
					})
					.finally(() => this.followBtnLoader = false);
			} else {
				this.dialogNeedLoginVisible = true;
			}
		},
		copyArtistLink() {
			navigator.clipboard.writeText(this.shareLink);
      this.copiedArtistLink = true;

      setTimeout(() => {
        this.copiedArtistLink = false;
      }, 2000);
		},
		toTop() {
			window.scrollTo({
				top: 0,
			});
		},
	},
}
</script>

<style lang="scss">
footer {
  margin-top: 200px !important;
}
</style>
